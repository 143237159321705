<template>
    <div class="container-fluid">

        <div class="row" style="width: 100%;margin:0px">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding:0px">
                <nav class="navbar navbar-expand-lg navbar-dark bg-dark" style="height: 200px;display:block;    margin: 0px !important">

                    <div style=" justify-content: space-between;display: flex;">

                        <img src="/img/blanco.svg" style="width: 125px;" />

                        <button @click="exit"
                            style="background-color: white; color: white; padding: 7px 20px; border: none; border-radius: 50px; ">
                            <i class="fas fa-sign-out-alt text-success text-sm opacity-10" style="font-size: 6px"></i> <span
                                style="color:#7306d2; font-size: 12px; font-weight: bold">Cerrar Sesión</span>
                        </button>
                        </div>

                        <p style="text-align: center; color:white; font-weight: bold;font-size:23px; margin-bottom: 0px;margin-top: 30px;    line-height: 28px;">Gracias por registrarte... ¡Ahora actualiza tu perfil!</p>


                </nav>

            </div>


            <div class="col-12 col-sm-12 col-md-2 col-lg-4 col-xl-4">

            </div>

            <div class="col-12 col-sm-12 col-md-8 col-lg-4 col-xl-4">

                <div class="card" style=" margin-top: -55px; ">
        
                    <div class="card-body" style="padding:20px !important">
                   <!-- contenido de la tarjeta -->
                        <!-- contenido de la tarjeta -->



                        <div class="row">

                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="margin-bottom:10px">

                                <form role="form" autocomplete="off" @submit.prevent="onSubmitSearch">



                                    <label for="example-text-input" class="form-control-label"
                                        style="font-weight: bold; font-size: 12px; ">Ingrese su número de cédula (*):</label>
                                    <div class="input-group mb-3">
                                        <input type="number" required :readonly="isDisabled"
                                            class="form-control col-lg-6" ref="myDni"
                                            placeholder="Número de cédula" v-model="user.cedula"
                                            aria-label="Número de cédula" aria-describedby="button-addon2">
                                        <button class="btn-outline-secondary btn2" :disabled="isDisabled" type="submit">
                                            <i class="fa fa-search" aria-hidden="true" style="margin-right: 5px;"></i>
                                            <b>Buscar</b>
                                        </button>

                                    </div>
                                </form>

                            </div>

                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <div class="row" v-if="mostrar">

                                    <div class="col-lg-8 offset-lg-2 " style="margin-bottom:10px">
                                        <label for="example-text-input"
                                            class="form-control-label align-middle">Nombres</label>
                                        <input class="form-control col-lg-12" type="text" autocomplete="off" readonly
                                            v-model="user.nombres" />
                                    </div>

                                    <div class="col-md-4" style="margin-bottom:10px">
                                        <label for="example-text-input" class="form-control-label">Fecha de
                                            nacimiento</label>
                                        <input class="form-control" type="date" disabled v-model="user.fechaNac" />
                                    </div>
                                    <div class="col-md-4" style="margin-bottom:10px">
                                        <label for="example-text-input" class="form-control-label">Sexo</label>
                                        <select class="form-control form-select" disabled :value="user.sexo">
                                            <option disabled value="">Elegir</option>
                                            <option>Masculino</option>
                                            <option>Femenino</option>
                                        </select>

                                    </div>
                                    <div class="col-md-4" style="margin-bottom:10px">
                                        <label for="example-text-input" class="form-control-label">Estado Civil</label>
                                        <input class="form-control" type="text" readonly :value="user.estadoCivil" />
                                    </div>




                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" align="center">

                                        <hr class="horizontal dark" />


                                        <button id="btn_update" type="button" :disabled="isDisabled2" class="btn btn-primary" style="width: 100%;"
                                            v-on:click="getSavePerfil()"> Actualizar mi perfil </button>
                                    </div>

                                </div>

                            </div>







                        </div>




                    </div>
                </div>

            </div>

            <div class="col-12 col-sm-12 col-md-2 col-lg-4 col-xl-4">

            </div>

            <div class="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2">

</div>

        <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 text-center align-items-center justify-content-xl-between"
            style="color:black !important;margin-top: 15px; ">
            <hr class="horizontal dark" />


            <a
              target="_blank"
              title="Facebook de WhatSivo"
              href="https://www.facebook.com/"
            >   <i class="fab fa-facebook-f redes" aria-hidden="true"></i>  </a>
            <a
              target="_blank"
              title="Instagram de WhatSivo"
              href="https://www.instagram.com/"
            > <i class="fab fa-instagram redes" aria-hidden="true"></i>  </a>
            <a
              target="_blank"
              title="Email de WhatSivo"
              href="mailto:hola@whatsivo.com?Subject=Tengo%20una%20pregunta"
            > <i  class="fas fa-envelope redes" aria-hidden="true"></i> </a>
            <a
              target="_blank"
              title="Tiktok de WhatSivo"
              href="https://www.tiktok.com"
            > <svg
            style="margin-bottom: 10px;"
              xmlns="http://www.w3.org/2000/svg"
              fill="#673bde"
              widht="10"
              height="22"
              viewBox="0 0 448 512"
            ><path d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z" /></svg></a>

            <p style="margin-top: 10px; margin-bottom:0; font-size: 12px;">  <a
              href="#"
              target="_blank"
            >Términos y condiciones</a> -  <a
              href="#"
              target="_blank"
            >Política de privacidad y condiciones de servicios</a> -  <a
              href="#"
              target="_blank"
            >Reclamos</a></p>

            <p style="margin-top: 0px; font-size: 12px;    margin-bottom: 10px;"> Copyright &copy; {{ year }} <a
              href="#"
              target="_blank"
            >- WhatSivo</a></p>

            </div>

            <div class="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2">

</div>

        </div>

    </div>
</template>
  
  
<script>
/*eslint-disable */


import myMixin from '../mixin.js'


export default {
    name: "editarPerfil",
    data() {

        const { logout } = myMixin()

       
        return {
            logout: logout,
            mostrar: false,
            isDisabled: false,
            isDisabled2:false,
            year: new Date().getFullYear(),
            userId: '', //datos_json.userId,
             token: '', //datos_json.tokenAuth,
            contieneCedula: '',
            user: {
                nombres: '',
                cedula: '',
                fechaNac: '',
                sexo: '',
                estadoCivil: ''
            }
        }
    },
    methods: {

        exit() {

         
            
            this.logout(this.userId, this.token, true);

        },

        onSubmitSearch(event) {
            event.preventDefault();

            this.getDatos();

        },
        getDatos() {


            let cedula = ''

            if ((String(this.user.cedula).length) === 9) {

                cedula = '0' + this.user.cedula
                this.user.cedula = cedula
                this.consultar()
            } else if ((String(this.user.cedula).length) === 10) {

              
                this.consultar()
            } else {

                this.$swal.fire({
                    title: "Cédula incorrecta!",
                    icon: 'warning',
                    width: '400px',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-secondary ml-1',
                    },
                    buttonsStyling: false,
                });

                this.user.cedula="";

            }

        },
        consultar() {

         
            this.$swal({

                html:
                    '<div class="row"><div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2"></div><div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"><div class="loader"> </div></div><div class="col-7 col-sm-7 col-md-7 col-lg-7 col-xl-7"><p style="margin:0px; font-size:16px; text-align:center; font-weight:bold">Consultando, espere</p></div><div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2"></div></div>',
                showCloseButton: false,
                showCancelButton: false,
                focusConfirm: false,
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                position: 'top',
            });

        
         
            this.$https.post('/profile/consultaDatos/', {tokenAuth:this.token, cedula: this.user.cedula }).then(response => {

                this.$swal.close()

                if (response.data.code == 200) {

  
                    this.mostrar = true
                    this.user.nombres = response.data.data.nombres.trim();
                    this.user.fechaNac= response.data.data.nacimiento
                    this.user.estadoCivil= response.data.data.estadocivil
                    let sexo = response.data.data.sexo

                    sexo === "1" ? (this.user.sexo = "Masculino") : (this.user.sexo = "Femenino");

                    this.isDisabled = true;



                }else{

                    if (response.data.code == 401) {
                        this.$toast.error(response.data.message, {
                                        position:"bottom-right",
                                        max: 10
                                        })
                
                        localStorage.removeItem('userData')
                        this.$store.state.user="";


                     // Redirect to login page
                     this.$router.push({ name: 'login' })

                    }else{

                        this.$toast.error(response.data.message, {
                                        position:"bottom-right",
                                        max: 10
                                        })

                    }

                }
            })
        },

        getSavePerfil() {
        

  

            const userId_json = { userId: this.userId, user: this.user }
            const user_string = JSON.stringify(userId_json)

            const dataEncripted = this.$encryptBackoffice.encrypt(user_string)


            document.getElementById('btn_update').innerHTML = '<i class="fas fa-spinner fa-pulse"></i>  Actualizando'
            this.isDisabled2=true;

            this.$https.post('/profile/updatePerfil/', { tokenAuth: this.token, data: dataEncripted }).then(response => {
                document.getElementById('btn_update').innerHTML = 'Actualizar mi perfil'

                this.isDisabled2=false;

                if (response.data.code == 200) {

                        this.$toast.success("Cédula registrada exitosamente!", {
                    position: "bottom-right",
                    max: 10
                })

                    const datos_json = JSON.parse(this.$store.state.user);
    
 
 
                    datos_json.dni = this.user.cedula
                    localStorage.setItem('userData', JSON.stringify(datos_json))

                    this.$store.state.user= JSON.stringify(datos_json);
                                            
                    this.$router.push({ name: 'password' })

              

                } else{
                    this.$toast.error(response.data.message, {
                    position: "bottom-right",
                    max: 10
                })

                  if(response.data.code == 401){

                      
                            localStorage.removeItem('userData')


                                    this.$store.state.user="";


                                    // Redirect to login page
                                    this.$router.push({ name: 'login' })

                            }else{
                                if( response.data.code === 402){

                                    this.user.cedula="";
                                    this.isDisabled=false;
                                    this.isDisabled2=false;
                                    this.mostrar=false;
                                    this.$refs.myDni.focus()

                                }
                            }

                } 
            }).catch(error => {

                this.$toast.error("2) Ocurrió un error inesperado: " + error, {
                    position: "bottom-right",
                    max: 10
                })


            })




        },

    },
    computed: {

    },
    mounted() {

 
        this.$refs.myDni.focus()

    },
    created() {


    },
    beforeUnmount() {
    
    },
};
</script>
  
<style scoped>
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.container-fluid {
    padding: 0px;
    margin: 0px;
}

.bg-dark {
    background: linear-gradient(87deg, #5e02ca, #8100d3) !important;
    height: 160px;
}


</style>
  