<template>
  <vue-final-modal v-model="showTerminos" :esc-to-close="false" :click-to-close="false" :prevent-click="false"
    classes="modal-container" content-class="modal-content">
    <span class="modal__title">Términos y condiciones de uso</span>
    <div class="modal__content">

      <p style="font-size:12px;text-align: justify;">
        En mi calidad de usuario (en adelante “el usuario” o “usuario”) de esta plataforma (en adelante plataforma
        “PLANPRO”) de titularidad de PLANPROCAD S.A., reconozco expresamente que al utilizarla debo sujetarme en todo
        momento a lo aquí establecido. Al acceder o utilizar esta plataforma web, <b>acepto expresa, libre y
          voluntariamente los siguientes Términos y Condiciones de Uso.</b>
      </p>
      <p style="font-size:12px;text-align: justify;">
        Reconozco y acepto que la plataforma PLANPRO en la persona de su administrador o titular, se reserva el derecho
        de
        negarme el acceso a la misma en cualquier caso que considere apropiado, en particular si yo como usuario:
      </p>

      <p style="font-size:12px;text-align: justify;">
      <ol>
        <li>Proporciono <b>datos falsos</b></li>
        <li><b>Incumplo</b> estos Términos y Condiciones de Uso de cualquier forma; y,</li>
        <li>Incumplo <b>cualquier normativa legal</b> aplicable respecto del acceso o el uso de la presente plataforma.
        </li>

      </ol>
      </p>

      <p style="font-size:12px;text-align: justify;">
        Acepta libre, voluntaria y expresamente que soy el único responsable del contenido que cargue, publique, envíe
        por
        correo electrónico, transmita o de cualquier forma ponga a disposición a través de esta plataforma.
      </p>


      <p style="font-size:12px;text-align: justify;">

        Adicionalmente me obligo expresa, libre y voluntariamente a <b>no utilizar la presente plataforma en forma
          alguna
          que sirva directa o indirectamente para:</b>
      </p>

      <p style="font-size:12px;text-align: justify;">
      <ul>
        <li>Dañar a otras personas o animales de cualquier forma; </li>
        <li>Realizar declaraciones falsas; </li>
        <li>Difundir de cualquier forma contenido que viole un derecho de propiedad intelectual de terceros, incluyendo
          pero no limitado a marcas, derechos de autor, secretos empresariales, patentes y diseños industriales; y,
        </li>
        <li>Violar cualquier ley o norma jurídica nacional o internacional. </li>
      </ul>
      </p>

      <p style="font-size:12px;text-align: justify;">

        Reconozco y acepto que la plataforma PLANPRO en la persona de su administrador o titular se reserva el derecho
        de
        modificar a su discreción los presentes términos y condiciones, comunicándome el particular, siempre de acuerdo
        con la ley ecuatoriana.

      </p>

      <p style="font-size:12px;text-align: justify;">

        <b>Datos Personales:</b> Autorizo expresamente a PLANPROCAD S.A. a utilizar mis datos personales entregados o
        generados por medio de mi utilización de la plataforma PLANPRO. Esta autorización incluye los siguientes usos:
      </p>

      <p style="font-size:12px;text-align: justify;">
      <ul>
        <li>Acceso a datos; </li>
        <li>Consulta de buró crediticio; </li>
      </ul>
      </p>


    </div>
    <div class="modal__action">

      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" align="center">

          <button type="button" class="btn btn-primary" @click="confirmar" style="width: 100%;">
            Confirmar
          </button>

        </div>


      </div>

    </div>
  </vue-final-modal>

  <h5 class="mt-3" style="text-align: center;margin-bottom: 15px;">Bienvenido a tu sistema de ventas</h5>


  <form role="form" autocomplete="off" @submit.prevent="onSubmitSesion">
    <div class="mb-2">
      <input placeholder="Ingrese su email" class="form-control col-lg-12" required type="email" autocomplete="off"
        v-model="user.user" />
    </div>

    <div class="mb-3">
      <input placeholder="Ingrese su contraseña" class="form-control col-lg-12" required type="password"
        autocomplete="off" v-model="user.password" />
    </div>


    <div class="text-center">
      <argon-button id="log-in" ref="log-in" type="submit" class="mt-1" variant="gradient" color="success"
        style="width: 100%; background: #00b63e!important;" size="lg">{{ textoButton }}
      </argon-button>

    </div>

  </form>
</template>


<script>
/*eslint-disable */
import ArgonButton from "@/components/ArgonButton.vue";



export default {
  name: "formulario",
  components: {
    ArgonButton,
  },
  props: ['textoButton', 'isLogin'],
  data() {
    return {

      user: {
        user: '',
        password: '',
      },

      //isActive: true,
      showTerminos: false,
    }
  },

  computed: {


  },

  activated() {
    this.user.password = ''
  },

  async mounted() {

    console.log('isLogin: ' + this.isLogin)
    //this.isActive = false
    //this.showTerminos=true;
  },
  methods: {

    generaRamdom() {
      let pass = "";
      let str = "abcdefghijklmnopqrstuvwxyz";

      for (let i = 0; i < 3; i++) {
        let char = Math.floor(Math.random() * str.length);
        pass += str.charAt(char);
      }

      return pass

    },

    async onSubmitSesion(event) {
      event.preventDefault();

      try {
        const response = await this.$https.post("/system/getLogin", this.user);

        // Verifica el código de respuesta o cualquier otra lógica de negocio
        if (response.data.code !== 200) {
          throw new Error(response.data.message || "Error en la autenticación");
        }

        if (response.data.code === 200) {
          let datos = response.data.datos

          let userData = {
            user: datos.email,
            movil: datos.phone,
            ruc: datos.ruc_dni,
            companie: datos.displayNameCompanies
          }

          let companies = datos.displayNameCompanies.toLowerCase().replace(/\s+/g, "").replace(/[^a-zA-Z ]/g, "");
          let generar = this.generaRamdom()
          let senderCompanies = companies + generar
          let sessionType = this.$encryptBackoffice.encrypt(datos.role);

          localStorage.setItem('userData', JSON.stringify(userData))
          localStorage.setItem("sender", senderCompanies);
          localStorage.setItem("white", response.data.token);
          localStorage.setItem("session", sessionType); //envio la session encryptada - Administrador - Normal

          if (datos.updatePass === "true") {
            //Aqui se envia al dashboard
            console.log('Tiene actualizada la clave');
            location.reload(true);
            this.$router.push({ name: 'Dashboard' })


          } else if (datos.updatePass === "false") {
            console.log('Debe actualizar la contraseña');
            this.$router.push({ name: 'password' })
          }

        }


      } catch (error) {

        localStorage.removeItem("userData");
        localStorage.removeItem("sender");
        localStorage.removeItem("white");
        localStorage.removeItem("session");

        this.$toast.error("Ocurrió un error: " + (error.response?.data?.message || error.message), {
          position: "top-right",
          max: 10,
        });

        localStorage.removeItem("userData");

        // Puedes también manejar errores específicos, como mostrar diferentes mensajes según el tipo de error
        if (error.response) {
          console.error("Error en la respuesta del servidor:", error.response.data);
        } else if (error.request) {
          console.error("No se recibió respuesta del servidor:", error.request);
        } else {
          console.error("Error al configurar la solicitud:", error.message);
        }
      }
    },




    showSuccessToast(message) {
      this.$toast.success(message, {
        position: "bottom-right",
        max: 10
      });
    },

    showErrorToast(message) {
      this.$toast.error(message, {
        position: "bottom-right",
        max: 10
      });
    },


    async seguridadInicioSesion(nombre, movil) {
      try {

        /* console.log('seguridadInicioSesion');
        console.log(nombre);
        console.log(movil); */

        const { data } = await this.$https.post('api/auth/detallesPeticion');
        //console.log(data);

        if (data.code == 200) {

          var today = new Date();
          var now = today.toLocaleString();
          let rspt = data

          let mensaje = `Hola 🙋🏻‍♂️ *${nombre}*, le notificamos que se inició sesión a tu cuenta el *${now}* con la siguiente información:\n\n
*IP:* ${rspt.IP}\n*Dispositivo:* ${rspt.resul.nameDevice}\n*Sistema operativo:* ${rspt.resul.os} ${rspt.resul.versionOs}\n*Navegador*: ${rspt.resul.browser}\n\n
*WhatSivo + fácil + masivo* 💻📱✅`;

          //Aqui debemos de enviar un mensaje

        }
      } catch (e) {
        console.log(e.message);
      }
    }



  },
  created() {


  },
  beforeUnmount() {

  },
};
</script>

<style scoped>
.bg-gradient-success {
  background-image: linear-gradient(310deg, #5e02ca 0%, #8100d3 100%);

}



.m-input.--md .m-input-input,
.m-input.--md .m-input-label {
  font-size: 12px !important;
  line-height: 1.5rem;
}

.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
  cursor: not-allowed;
  pointer-events: auto;
}
</style>