<template>

    <div>

        <div class="row" v-if="loading">

            <div v-if="loading" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" align="center" style="min-height: calc(var(--vh, 1vh) * 80);display: flex;">
                <div style="margin: auto;text-align: center;">
                    <i style="font-size: 50px" class="fas fa-spinner fa-pulse"></i> 
          

                <p style="font-size: 16px; font-weight: bold;">
                Comprobando usuario
                </p>

                </div>
                
            </div>


            </div>

            <div class="row" v-else>

                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="    padding: 0px;">

                    <div class="card" style="border-radius: 0px;"> 

                        <ul class="nav nav-pills" id="pills-tab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <button @click="VentasLoad" class="nav-link"  :class="activoPanel === 0 ? 'active' : ''" id="pills-ventas-tab" data-bs-toggle="pill" data-bs-target="#pills-ventas" type="button" role="tab" aria-controls="pills-ventas" aria-selected="true"><i style="font-size:14px; margin-right: 5px;" class="fa fa-shopping-cart text-success"></i> Ventas</button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button  @click="RetirosLoad" class="nav-link" :class="activoPanel === 1 ? 'active' : ''" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false"><i style="font-size:14px; margin-right: 5px;" class="fa fa-money text-success"></i> Retiros</button>
                            </li>
               

                         
                            </ul>

                    </div>



                </div>

                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" style="margin-top:15px;     padding: 0px;"> 
                    <div class="card"> 

                    <div class="tab-content" id="pills-tabContent">
                    <div class="tab-pane fade" :class="activoPanel === 0 ? 'show active' : ''" id="pills-ventas" role="tabpanel" aria-labelledby="pills-ventas-tab" tabindex="0">
                      <listado-ventas v-if="ifVentas" :key="componentKeyVentas"  :user-id="userId" :token-auth="tokenAuth" :id-admin="idAdmin" />

                    </div>
                    <div class="tab-pane fade" :class="activoPanel === 1 ? 'show active' : ''" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabindex="0">
                    <listado-retiros v-if="ifRetiros" :key="componentKeyRetiros"  :user-id="userId" :token-auth="tokenAuth" :id-admin="idAdmin" />
                  </div>
                    </div>

                    </div>
                </div>

            

            </div>


    </div>

  
  </template>
  
  <script>
/*eslint-disable */
import "bootstrap" //no eliminar, este le da el evento al nav pills
import ListadoVentas from './ListadoVentas.vue'
import ListadoRetiros from './ListadoRetiros.vue'

  export default {
    components: {
      ListadoVentas,
      ListadoRetiros
    },
    data() {


  
        let datos = JSON.parse(this.$store.state.user);

  
      return {
        isMobile: this.$isMobile,
        userId: datos.userId,
        tokenAuth: datos.tokenAuth,
        idAdmin:"",
        loading:true,
        componentKey:0,
        activoPanel:1,
        componentKeyVentas:100,
        ifVentas:false,
        ifRetiros:false,
        componentKeyRetiros:200,
      }
    },
    watch:{
      $route (to, from){

      
  
            

      var hash = to.hash.substring(1); //Puts hash in variable, and removes the # character

        

      switch(hash) {

        case "ventas":
            this.activoPanel=0;

              this.ifVentas=true;
            
              break;
            case "retiros":
            this.activoPanel=1;

              this.ifRetiros=true;
            
              break;
          
          
            default:
            this.activoPanel=0;
            this.ifVentas=true;
        
          }
          

      }
},
    created() {


    },
    activated() {
    
          if (this.isMobile){
        
            this.$eventBus.emit('inactiveShowMenu')
          }


        
        
    },
    mounted() {

      this.$eventBus.on('reiniciarListadoVentasAdmin', () => {
      this.componentKeyVentas += 1
    });


    this.$eventBus.on('reiniciarListadoRetirosAdmin', () => {
      this.componentKeyRetiros += 1
    });

  
      this.$eventBus.on('reiniciarAdmin', () => {
        this.componentKey += 1
      });

      

      document.title = 'XTRIMPRO - Administración';

      this.init();



  
    },
    methods: {


  
    init(){
    
      const userIdEncripted = this.$encryptBackoffice.encrypt(this.userId)

    this.$https.post('/administracion/check/', { tokenAuth: this.tokenAuth, userId: userIdEncripted }).then(response => {

   
        
      if (response.data.code == 200) {

        this.idAdmin= response.data["id"];

   
        
        this.loading = false;

        if(window.location.hash) {
          var hash = window.location.hash.substring(1); //Puts hash in variable, and removes the # character

  
     
          switch(hash) {
            case "estadisticas":
            this.activoPanel=0;
  
              this.ifVentas=true;
            
              break;
            case "retiros":
            this.activoPanel=1;
  
              this.ifRetiros=true;
            
              break;
            
          
            default:
              this.activoPanel=0;
              this.ifVentas=true;
            
              }
         
              }else{

                this.activoPanel=0;
                  this.ifVentas=true;


            }
          
 
            

        
      } else {
        this.loading = false;

        if (response.data.code == 401) {
          
              
            localStorage.removeItem('userData')
            this.$store.state.user="";



            // Redirect to login page
            this.$router.push({ name: 'login' })
        } else {

            if (response.data.code == 403) {

                this.$router.push({ name: 'Dashboard' })

            }
          
        }
      }
    }).catch(error => {
         this.init();
    })
    },

    RetirosLoad(){
      this.ifRetiros = true

    },

    VentasLoad(){
      this.ifVentas = true
    }
    },
  }
  </script>
  
  <style lang="scss" >

  
  </style>
  