<template>
  <div class="mt-2" style="padding-left: 10px; padding-right: 10px; padding-left: 10px;">
    <div class="row">

      <div class="col-12">
        <swiper :slides-per-view="3" :space-between="15" :centered-slides="false" :navigation="false"
          :pagination="false" :breakpoints="{
            200: { slidesPerView: 1.1 },
            400: { slidesPerView: 1.1 },
            550: { slidesPerView: 2.5 },
            768: { slidesPerView: 3.5 },
            1024: { slidesPerView: 2.5 },
            1200: { slidesPerView: 4 },
          }">
          <swiper-slide v-for="(card, index) in cards" :key="index">
            <TarjetaResumen :icon="card.icon" :title="card.title" :cantidad="card.cantidad"
              :backgroundColor="card.backgroundColor" :loading="loading" @refresh="refreshCard(index)" />
          </swiper-slide>
        </swiper>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-sm-12 col-lg-4 col-md-4 col-xl-3"
        style="padding-top:10px; overflow: auto; margin: 0 auto;">
        <qr />

      </div>

      <div class="col-12 col-sm-12 col-lg-8 col-md-8 col-xl-9" style="margin-top:10px; overflow: auto;">
        <Listado-enviados />
      </div>



      <div
        class="float_whatsapp d-none d-sm-none d-md-inline d-lg-inline d-xl-inline col-12 col-sm-12 col-lg-12 col-md-12 col-xl-12">

        <a :href="textAyuda" target="_blank">

          <div style=" justify-content: space-around;display: flex;">

            <svg width="30" class="my-float" style="    margin-top: 7px;margin-left: 10px;" height="30"
              viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="currentcolor">
              <path
                d="M13.601 2.326A7.854 7.854.0 007.994.0C3.627.0.068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933.0 003.79.965h.004c4.368.0 7.926-3.558 7.93-7.93A7.898 7.898.0 0013.6 2.326zM7.994 14.521a6.573 6.573.0 01-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251A6.56 6.56.0 011.407 7.922c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56.0 014.66 1.931 6.557 6.557.0 011.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729.0 00-.529.247c-.182.198-.691.677-.691 1.654.0.977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
            </svg>

            <p class="dudas_txt">¿Necesitas ayuda? Contácta con soporte.</p>


          </div>


        </a>
      </div>


      <div class="d-inline d-sm-inline d-md-none d-lg-none d-xl-none col-12 col-sm-12 col-lg-12 col-md-12 col-xl-12">

        <a class="float" :href="textAyuda" target="_blank">


          <svg width="30" class="my-float" style="margin-top: 14px;" height="30" viewBox="0 0 16 16"
            xmlns="http://www.w3.org/2000/svg" fill="currentcolor">
            <path
              d="M13.601 2.326A7.854 7.854.0 007.994.0C3.627.0.068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933.0 003.79.965h.004c4.368.0 7.926-3.558 7.93-7.93A7.898 7.898.0 0013.6 2.326zM7.994 14.521a6.573 6.573.0 01-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251A6.56 6.56.0 011.407 7.922c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56.0 014.66 1.931 6.557 6.557.0 011.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729.0 00-.529.247c-.182.198-.691.677-.691 1.654.0.977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
          </svg>


        </a>
      </div>


    </div>

  </div>
</template>
<script>
/*eslint-disable */
import Qr from "./CargaQR.vue";
import ListadoEnviados from "./ListadoEnviados.vue";
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import TarjetaResumen from './CardComponent.vue';


export default {
  name: "dashboard-default",
  data() {

    return {
      textAyuda: "https://api.whatsapp.com/send?phone=593985067512&text=Hola,%20soy%20" + JSON.parse(localStorage.getItem('userData')).user + "%20y%20deseo%20obtener%20ayuda%20en%20WHATSIVO",
      showModal: false,
      showModal2: false,
      isMobile: this.$isMobile,
      loading: true,
      abcd: JSON.parse(localStorage.getItem('userData')).ruc,
      displayName: '',
      Authorization: JSON.parse(localStorage.getItem('userData')).user,
      tokenAuth: 'Bearer ' + localStorage.getItem('white'),
      componentKeyFormVenta: 500,
      cards: [
        { icon: 'fas fa-paper-plane', title: 'Cantidad de Mensajes', cantidad: 0, backgroundColor: '#ffffff' },
        { icon: 'fas fa-tags', title: 'Mensajes Enviados', cantidad: 0, backgroundColor: '#ffffff' },
        { icon: 'fas fa-times', title: 'No Enviados', cantidad: 0, backgroundColor: '#ffffff' },
        { icon: 'fas fa-mobile-alt', title: 'Lineas Autorizadas', cantidad: 0, backgroundColor: '#ffffff' },
      ]
    }
  },
  components: {
    Qr,
    ListadoEnviados,
    Swiper,
    SwiperSlide,
    TarjetaResumen
  },

  methods: {

    closed() {
      this.$eventBus.emit('reiniciarFormVentaDashboard');

    },

    async cargaCantidadSms() {

      try {
        const response = await this.$https.post("/system/getMessageAmount", { email: this.Authorization }, { headers: { Authorization: this.tokenAuth } });

        if (response.data.code === 200) {

          this.cards[0].cantidad = Number(response.data.datos.messageSend) + Number(response.data.datos.messageNotSend); // Total Mensajes
          this.cards[1].cantidad = response.data.datos.messageSend; // Mensajes Enviados
          this.cards[2].cantidad = response.data.datos.messageNotSend;  // No Enviados
          this.cards[3].cantidad = 10; // Líneas Autorizadas
        }

      } catch (error) {
        console.error(error);

        if (error.response.status === 401) {
          this.$swal
            .fire({
              html: "<span style='font-size:14px'>Tu sesión se ha expirado, vuelva a iniciar sesión.. </span>",
              position: "center",
              allowEscapeKey: false,
              allowOutsideClick: false,
              showConfirmButton: false, // Oculta el botón de confirmación
              timer: 4000, // Duración en milisegundos (4 segundos)
              didOpen: () => {
                this.$swal.showLoading();
              },
            })
            .then(
              () => {
                // Este bloque se ejecuta después de que se cierre automáticamente la alerta
                console.log("closed by timer!!!!");

                localStorage.clear();
                location.reload(true);
              },
              (dismiss) => {
                if (dismiss === "timer") {
                  // Este bloque se ejecuta si la alerta se cierra automáticamente por el temporizador
                  console.log("closed by timer!!!!");
                  this.$swal.fire({
                    title: "Finished!",
                    icon: "success",
                    showConfirmButton: false,
                  });
                }
              }
            );
        }


      } finally {
        this.loading = false;
      }

    }

  },

  created() {

  },

  beforeUnmount() {


  },
  activated() {


  },
  deactivated() {
    console.log('About has been deactivated')
  },



  mounted() {

    if (this.isMobile) {
      this.$eventBus.emit('activeShowMenu')
    }


    this.cargaCantidadSms()




    //console.log('Carga Dashboard');

    //Aqui me actualiza la lista
    //this.$eventBus.emit('showCargaLista')

  },
};
</script>

<style scoped>
.card {
  overflow: hidden;
}
</style>