<template>
    <div class="row">

        <div class="col-8 col-sm-6 col-md-8 col-lg-6 col-xl-6">
            <label class="form-control-label mb-0" style="font-size: 12px;">Selecciona una campaña</label>
            <div class="d-flex align-items-center mb-2">
                <select class="form-select sm" v-model="selectedCampaign">
                    <option v-for="campaign in campaigns" :key="campaign.campaign" :value="campaign.campaign">
                        {{ campaign.campaign }}
                    </option>
                </select>
                <i class="fa fa-plus-circle text-sm opacity-10 ms-2" style="cursor: pointer; font-size: 20px;"
                    @click="loadModal"></i>

            </div>

        </div>

        <div class="col-4 col-sm-6 col-md-4 col-lg-6 col-xl-6">
            <div class="d-flex flex-column align-items-end">
                <div class="d-flex align-items-center mb-2">
                    <div class="ms-auto">
                        <button type="button" class="btn btn-primary btn-sm me-1" @click="openFileDialogExcel">
                            <i class="fas fa-file-excel" style="margin-right: 5px;"></i>
                            Subir
                        </button>

                        <input ref="fileInput2" type="file" style="display: none;"
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" id="archivoExcel"
                            v-on:change="FileUploadExcel">

                        <button type="button" class="btn btn-primary btn-sm me-1" @click="validaEnvios">
                            <i class="fab fa-whatsapp" style="margin-right: 5px;"></i>
                            Enviar
                        </button>
                    </div>
                </div>

                <div class="d-flex justify-content-end mt-2">
                    <label v-if="mostrandoContador" class="form-control-label mb-0" style="font-size: 12px;">Contador de
                        mensajes enviados {{ this.contadorEnvios }}</label>
                </div>
            </div>
        </div>





        <vue-final-modal v-model="showModal" classes="modal-container" content-class="modal-content">
            <div class="modal-header d-flex justify-content-center rounded mb-1"
                style="background-color: #ff9400; padding: 10px;">
                <h6 class="modal-title text-center" style="color: white; margin: 0;">Administrar Campañas</h6>
            </div>
            <div class="row p-3">
                <div class="col-12">
                    <form role="form" autocomplete="off" @submit.prevent="savedCampanas">
                        <div class=" mb-2">
                            <input type="text" class="form-control" required autocomplete="off" v-model="campana.name"
                                placeholder="Ingrese la campaña" />
                        </div>

                        <div class="d-flex justify-content-end mb-3">
                            <button type="submit" class="btn btn-primary btn-sm">
                                <i class="fas fa-save me-1"></i>Guardar
                            </button>
                        </div>
                    </form>

                </div>
                <div class="col-12">

                    <DataTable :columns="columns" :options="options" :data="transactions" class="table tablesorter"
                        width="100%">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th style="display: none;">Id</th>
                                <th data-priority="1">Nombres</th>
                                <th data-priority="2" style="text-align: center;">Acción</th>
                            </tr>
                        </thead>
                    </DataTable>

                </div>
            </div>
        </vue-final-modal>




    </div>
</template>



<script>
/* eslint-disable */
import DataTable from 'datatables.net-vue3'
import DataTablesCore from 'datatables.net-bs5';
import 'datatables.net-responsive';

DataTable.use(DataTablesCore);

export default {
    name: "formularioCampanas-default",
    components: {
        DataTable
    },
    data() {
        return {
            showModal: false,
            contadorEnvios: 0,
            mostrandoContador: false,
            Authorization: localStorage.getItem("authorization"),
            email: JSON.parse(localStorage.getItem('userData')).user,
            loading: true,
            campana: {
                name: null
            },
            listExcel: [],
            selectedCampaign: null, // Variable para almacenar la opción seleccionada
            campanaName: null,
            textPublicidad: null,
            imagenReceived: null,
            extensionReceived: null,
            campaigns: [],
            transactions: [],
            columns: [
                {
                    data: null,
                    render: function (data, type, row, meta) {
                        const index = meta.row + 1;
                        return index;
                    }
                },
                {
                    data: 'Id',
                    visible: false // Oculta la columna 'Id' pero sigue disponible en el código
                },
                { data: 'campaign' },
                {
                    data: null,
                    render: function (data, type, row, meta) {
                        const dataObject = encodeURIComponent(JSON.stringify(row));
                        const deleteIcon = `<i data-datos="${dataObject}" data-action="delete" class="dataAction redes fas fa-trash-alt text-center" style="cursor:pointer; color: red; font-size: 14px"></i>`;

                        return deleteIcon;
                    },
                    className: 'column-centered'
                }
            ],
            options: {
                searching: false, //Cuadro de busqueda
                responsive: true,
                lengthChange: false, //Filtro
                "ordering": false,
                columnDefs: [
                    { responsivePriority: 1, targets: 0 },
                    { responsivePriority: 2, targets: -1 }
                ],
                lengthMenu: [
                    [3, 5, 15, 25, 35, 50, -1],
                    [3, 5, 15, 25, 35, 50, "All"],
                ],
                pageLength: 3,
                "language": {
                    "search": "Buscar:",
                    "sInfo": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
                    "sInfoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
                    "searchPlaceholder": "Ingrese algún dato",
                    "zeroRecords": "Lo sentimos, no se encontraron resultados",
                    "processing": "Buscando. Espere por favor...",
                    "sLengthMenu": "Mostrar _MENU_ registros",
                    "oPaginate": {
                        "sFirst": "Primero",
                        "sLast": "Último",
                        "sNext": "Siguiente",
                        "sPrevious": "Anterior"
                    },

                }
            }
        };
    },
    watch: {
        'campana.name'(name) {
            if (name != null && typeof name === 'string') {
                this.campana.name = name.toUpperCase();
            }
        },
    },
    activated() { },
    methods: {
        loadModal() {
            this.showModal = true
        },

        openFileDialogExcel() {
            this.$refs.fileInput2.click();
        },

        FileUploadExcel() {

            // Obtener el input de archivo y validar que se haya seleccionado un archivo
            let input = document.getElementById("archivoExcel");

            if (!input || !input.files.length) {
                this.showErrorToast('Por favor, seleccione un archivo.');
                return;
            }

            const file = input.files[0];

            // Verificar si el archivo es un archivo XLSX
            const validMimeType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
            if (file.type !== validMimeType) {
                this.showErrorToast('Seleccione un archivo XLSX válido.');
                return;
            }

            this.$eventBus.emit('showDatosExcel', file)
            input.value = '';

        },

        validaEnvios() {

            this.$eventBus.emit("getTextoPublicidad");
            this.$eventBus.emit("sendExcel");
            this.$eventBus.emit("getImagen");

            if (!this.selectedCampaign) {
                return this.showErrorToast('Debe seleccionar una campaña')
            }

            //Aqui validamos para que los datos se carguen correctamente
            setTimeout(() => {
                if (!this.textPublicidad) {
                    return this.showErrorToast('El mensaje no puede estar vacio')
                }

                if (this.textPublicidad.indexOf('@@NOMBRE@@') === -1) {
                    return this.showErrorToast('Variable faltante @@NOMBRE@@')
                }

                if (this.listExcel.length === 0) {

                    return this.showErrorToast('Debe importar la lista de Excel')

                } else {

                    this.campanaName = this.selectedCampaign

                    this.$swal({
                        title: "¿Deseas Enviar los mensajes?",
                        icon: 'question',
                        width: '400px',
                        showCancelButton: true,
                        confirmButtonText: 'Si',
                        cancelButtonText: 'No',
                        customClass: {
                            confirmButton: 'btn btn-primary',
                            cancelButton: 'btn btn-outline-secondary ml-1',
                        },
                        buttonsStyling: false,
                        showLoaderOnConfirm: true,
                        preConfirm: () => {
                            //Esta propiedad te permite definir una función que se ejecuta alguna acción antes de confirmar, si es necesario
                        },
                        allowOutsideClick: () => !this.$swal.isLoading(),
                    }).then(async (result) => {
                        if (result.isConfirmed) {

                            this.sendServerWhatSivo()


                        }
                    })


                }
            }, 1000);

        },

        async sendServerWhatSivo() {
            try {

                this.$swal.fire({
                html: "<span style='font-size:14px'>Enviando mensajes, por favor espere.. </span>",
                position: "center",
                allowEscapeKey: false,
                allowOutsideClick: false,
                didOpen: () => {
                    this.$swal.showLoading();
                }
            }).then(
                () => { },
                (dismiss) => {
                    if (dismiss === 'timer') {
                        console.log('closed by timer!!!!');
                        this.$swal.fire({
                            title: 'Finished!',
                            icon: 'success',
                            showConfirmButton: false
                        })
                    }
                })

                const config = {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                };

                this.contadorEnvios = 0; // Reinicia el contador al inicio
                this.mostrandoContador = true; // Muestra el contador

                for (const [index, { MOVIL, NOMBRE, VARIABLE1, VARIABLE2, VARIABLE3 }] of this.listExcel.entries()) {
                    const msjRemplazado = this.textPublicidad.replace(/@@(\w+)@@/g, (_, match) => {
                        const variables = { MOVIL, NOMBRE, VARIABLE1, VARIABLE2, VARIABLE3 };
                        return `*${variables[match] || match}*`;
                    });

                    const commonPayload = {
                        sender: localStorage.getItem("sender"),
                        email: this.email,
                        campana: this.campanaName,
                        displayName: NOMBRE,
                        message: msjRemplazado,
                        number: MOVIL
                    };

                    try {
                        let response;
                        if (!this.extensionReceived) {

                            response = await this.$https.post("/envios/getMessageText", commonPayload);

                        } else if (['imagen', 'document'].includes(this.extensionReceived)) {

                            let data = new FormData();
                            Object.entries({ ...commonPayload, tipoMessage: this.extensionReceived }).forEach(([key, value]) => {
                                data.append(key, value);
                            });
                            data.append("file", this.imagenReceived);

                            response = await this.$https.post("/envios/getMessageMedia", data, config);

                        }

                        if (response?.data.code === 403) {
                            this.showErrorToast('El cliente de WhatsApp no ha sido inicializado');
                            break; // Detiene el ciclo si se recibe 403
                        }

                        this.contadorEnvios++;

                    } catch (error) {                        

                        if(error.response.data.code !== 422){
                            console.error('Error al enviar mensaje:', error);
                            break; // Detiene el ciclo si ocurre un error
                        }
                        
                    }

                    if (index < this.listExcel.length - 1) {
                        await new Promise(resolve => setTimeout(resolve, 3000));
                    }
                }


                // SweetAlert al finalizar todos los envíos
                this.$swal({
                    title: 'Envíos completados',
                    text: `Se han enviado ${this.contadorEnvios} mensajes correctamente.`,
                    icon: 'success',
                    confirmButtonText: 'Aceptar'
                });


            } catch (error) {
                console.error('Error inesperado:', error);
            } finally {
                this.mostrandoContador = false; // Oculta el contador al finalizar
                this.contadorEnvios = 0; // Reinicia el contador
            }
        },

        async savedCampanas() {
            this.$swal({
                title: "¿Deseas Guardar la Campaña?",
                icon: 'question',
                width: '400px',
                showCancelButton: true,
                confirmButtonText: 'Si',
                cancelButtonText: 'No',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-secondary ml-1',
                },
                buttonsStyling: false,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    //Esta propiedad te permite definir una función que se ejecuta alguna acción antes de confirmar, si es necesario
                },
                allowOutsideClick: () => !this.$swal.isLoading(),
            }).then(async (result) => {
                if (result.isConfirmed) {

                    try {

                        const response = await this.$https.post("/system/getAddCampanas", { campana: this.campana.name, email: this.email });

                        if (response.data.code !== 200) {
                            throw new Error(response.data.message || "Error en la autenticación");
                        }

                        if (response.data.code === 200) {
                            this.getLoadCampanas()
                            this.showSuccessToast(response.data.message)
                        }

                    } catch (error) {
                        this.showErrorToast("Ocurrió un error: " + (error.response?.data?.message || error.message));
                    }


                }
            })
        },

        async getLoadCampanas() {
            try {
                const response = await this.$https.post("/system/getLoadCampanas", { email: this.email });

                // Verifica el código de respuesta o cualquier otra lógica de negocio
                if (response.data.code !== 200) {
                    throw new Error(response.data.message || "Error en la autenticación");
                }

                if (response.data.code === 200) {
                    this.transactions = response.data.datos
                    this.campaigns = response.data.datos

                    if (this.campaigns.length > 0) {
                        this.selectedCampaign = this.campaigns[0].campaign;
                    }
                }

            } catch (error) {
                this.showErrorToast("Ocurrió un error: " + (error.response?.data?.message || error.message));
            }

        },

        async getDelete(data) {

            this.$swal({
                title: "¿Deseas Eliminar la Campaña?",
                icon: 'question',
                width: '400px',
                showCancelButton: true,
                confirmButtonText: 'Si',
                cancelButtonText: 'No',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-secondary ml-1',
                },
                buttonsStyling: false,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    //Esta propiedad te permite definir una función que se ejecuta alguna acción antes de confirmar, si es necesario
                },
                allowOutsideClick: () => !this.$swal.isLoading(),
            }).then(async (result) => {
                if (result.isConfirmed) {

                    try {

                        const response = await this.$https.post("/system/getDeleteCampanas", { idCampana: data.Id });

                        if (response.data.code !== 200) {
                            throw new Error(response.data.message || "Error en la autenticación");
                        }

                        if (response.data.code === 200) {
                            this.getLoadCampanas()
                            this.showSuccessToast(response.data.message)
                        }

                    } catch (error) {
                        this.showErrorToast("Ocurrió un error: " + (error.response?.data?.message || error.message));
                    }

                }
            })



        }



    },
    mounted() {

        if (this.isMobile) {
            this.$eventBus.emit("activeShowMenu");
        }

        this.$eventBus.on('getSendText', (datos) => {
            this.textPublicidad = datos
        })

        this.$eventBus.on('recibeDatosExcel', (datos) => {
            this.listExcel = datos
        })

        this.$eventBus.on('getSendImagen', (datos) => {
            this.imagenReceived = datos.imagen
            this.extensionReceived = datos.extension;
        })

        const vm = this;

        $(document).off("click", ".dataAction");
        $(document).on("click", ".dataAction", function (evt) {
            const dataString = $(this).data("datos");
            const data = JSON.parse(decodeURIComponent(dataString));

            vm.getDelete(data)

        })





        this.getLoadCampanas()






    }
};
</script>


<style lang="scss">
@import "datatables.net-dt";
@import 'datatables.net-responsive-dt';
</style>