<template>
    <div class="card mt-1" style="margin-bottom:10px">

        <div class=" card-body">

            <div class="row">

                <div class="col-10 col-sm-10 col-md-11 col-lg-11 col-xl-11">

                    <p style="margin:10px; font-weight: bold;"><i style="margin-right: 10px;" class="fas fa-list"></i>
                        LISTADO DE PERSONAS A ENVIAR</p>

                </div>


                <div class="col-2 col-sm-2 col-md-1 col-lg-1 col-xl-1" align="center" style="display:flex">
                    <Skeletor v-if="loading" :shimmer="true" height="20" width="100%" style="margin:auto" />
                    <i v-else @click="refres" style="cursor:pointer; margin:auto"
                        class="fas fa-sync text-sm opacity-10"></i>
                </div>

            </div>

            <div v-if="loading" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding:0px">

                <div class="row">

                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">

                        <Skeletor :shimmer="true" height="40" width="50%" />

                    </div>

                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">

                        <Skeletor :shimmer="true" height="10" pill width="30%" style="margin-bottom: 10px;" />
                        <Skeletor :shimmer="true" height="40" width="100%" />

                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <table class="table tablesorter">
                            <thead class="thead-light">
                                <tr>
                                    <th>
                                        <Skeletor :shimmer="true" height="10" width="100%" />
                                    </th>
                                    <th>
                                        <Skeletor :shimmer="true" height="10" width="100%" />
                                    </th>
                                    <th>
                                        <Skeletor :shimmer="true" height="10" width="100%" />
                                    </th>

                                    <th>
                                        <Skeletor :shimmer="true" height="10" width="100%" />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>


                                <tr>

                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>
                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>
                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>

                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>





                                </tr>
                                <tr>

                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>
                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>
                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>

                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>





                                </tr>
                                <tr>

                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>
                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>
                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>

                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>


                                </tr>

                            </tbody>
                        </table>

                    </div>

                    <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
                        <Skeletor :shimmer="true" height="10" pill width="80%" />
                    </div>

                    <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">

                        <div class="row">
                            <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                <Skeletor :shimmer="true" height="10" width="100%" />
                            </div>

                            <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4" align="center">
                                <Skeletor :shimmer="true" circle size="30" />
                            </div>

                            <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                <Skeletor :shimmer="true" height="10" width="100%" />
                            </div>

                            <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                <Skeletor :shimmer="true" height="10" width="100%" />
                            </div>


                        </div>


                    </div>

                </div>

            </div>

            <DataTable v-else :columns="columns" :options="options" :data="transactions" class="table tablesorter"
                width="100%">
                <thead>
                    <tr>
                        <th style="width: 5%">#</th>
                        <th data-priority="1" style="width: 20%">NOMBRE</th>
                        <th style=" text-align: center; width: 20%">MOVIL</th>
                        <th style=" text-align: center; width: 8%">VARIABLE1</th>
                        <th style=" text-align: center; width: 8%">VARIABLE2</th>
                        <th style=" text-align: center; width: 8%">VARIABLE3</th>
                    </tr>
                </thead>





            </DataTable>


        </div>





    </div>
</template>


<script>
/* eslint-disable */
import reasXlsFile from "read-excel-file";
import DataTable from 'datatables.net-vue3'
import DataTablesCore from 'datatables.net-bs5';
import 'datatables.net-responsive';


DataTable.use(DataTablesCore);


export default {
    name: "Lista-Exc",
    components: {
        DataTable
    },

    data() {
        return {
            loading: false,
            isMobile: this.$isMobile,
            transactions: [],
            columns: [
                { data: 'idRegistro' },
                { data: 'NOMBRE' },
                { data: 'MOVIL', className: 'text-center' },
                { data: 'VARIABLE1', className: 'text-center', defaultContent: "-" },
                { data: 'VARIABLE2', className: 'text-center', defaultContent: "-" },
                { data: 'VARIABLE3', className: 'text-center', defaultContent: "-" },
            ],
            options: {
                responsive: true,
                "ordering": false,
                columnDefs: [
                    { responsivePriority: 1, targets: 0 },
                    { responsivePriority: 2, targets: -1 }
                ],
                lengthMenu: [
                    [5, 15, 25, 35, 50, -1],
                    [5, 15, 25, 35, 50, "All"],
                ],
                pageLength: 5,
                "language": {
                    "search": "Buscar:",
                    "sInfo": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
                    "sInfoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
                    "searchPlaceholder": "Ingrese algún dato",
                    "zeroRecords": "Lo sentimos, no se encontraron resultados",
                    "processing": "Buscando. Espere por favor...",
                    "sLengthMenu": "Mostrar _MENU_ registros",
                    "oPaginate": {
                        "sFirst": "Primero",
                        "sLast": "Último",
                        "sNext": "Siguiente",
                        "sPrevious": "Anterior"
                    },

                }
            },

        };
    },
    computed: {},
    watch: {},

    activated() { },
    mounted() {


        if (this.isMobile) {
            this.$eventBus.emit("activeShowMenu");
        }

        //Esto me envia los datos de la grilla para enviar los msj
        this.$eventBus.on('sendExcel', () => {
            this.sendDatosExcel()
        });

        //Esto me carga la lista en la grilla
        this.$eventBus.on('showDatosExcel', (datos) => {         
                this.FileUploadExcel(datos)
        })

    },
    methods: {
        sendDatosExcel(){            
            this.$eventBus.emit("recibeDatosExcel", this.transactions);
        },

        FileUploadExcel(file) {
            this.items = [];
            this.transactions = [];           

            // Procesar el archivo
            reasXlsFile(file).then((rows) => {
                if (rows.length < 2) {
                    this.showErrorToast('El archivo no contiene suficientes datos.');
                    return;
                }

                this.items = rows.slice(1).map((row, index) => ({
                    idRegistro: index + 1,
                    NOMBRE: row[0],
                    MOVIL: row[1],
                    VARIABLE1: row[2],
                    VARIABLE2: row[3],
                    VARIABLE3: row[4],
                }));

                this.transactions = this.items

                if(this.transactions){
                    this.showSuccessToast('Datos cargados exitosamente.');
                }

            }).catch(() => {
                this.showErrorToast('Ocurrió un error al procesar el archivo.');
            });
        }

    }

}



</script>
