<template>
    <div class="mt-3 full-height d-flex">
        <!-- Columna para la lista de chats -->
        <div class="chat-list full-height">
            <lista-contactos />
        </div>

        <div class="chat-content full-height">
            <!-- Columna para el contenido del chat -->
            <content-chat />
        </div>        
    </div>
</template>

<script>
/* eslint-disable */
import ListaContactos from './FilaContact.vue';
import ContentChat from './ContentMessage.vue';

export default {
    name: "Chat",
    components: {
        ListaContactos,
        ContentChat
    },
    data() {
        return {
            chats: [

            ],

        };
    },
    mounted() {

    },
    methods: {

    }
}
</script>

<style scoped>
.full-height {
    height: 90vh;
}

.chat-list {
    width: 30%;
    /* Ajusta según sea necesario */
    border-right: 1px solid #ddd;
    /* Línea divisoria */
}

.chat-content {
    width: 70%;
    height: 100%;
}
</style>
