<template>
    <div class="card full-height">
        <div class="header d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
                <img :src="selectedChat.img" alt="Foto de perfil" class="rounded-circle me-2" width="40">
                <div class="contact-info">
                    <strong>{{ selectedChat.name }}</strong>
                    <small>últ. vez hoy a la(s) 9:43</small>
                </div>
            </div>
            <div class="d-flex align-items-center">
                <i class="fas fa-users me-3" style="cursor: pointer;"></i>
                <i class="fas fa-sync-alt me-3" style="cursor: pointer;"></i>
                <i class="fas fa-comment-alt me-3" style="cursor: pointer;"></i>
            </div>
        </div>

        <div class="card-body d-flex flex-column full-height">
            <div id="messages" class="messages flex-grow-1 border p-3 mb-3">
                <!-- Mensajes aquí -->
            </div>
            <form @submit.prevent="sendMessage">
                <div class="message-input d-flex align-items-center mb-3">
                    <i class="fas fa-smile me-3" @click="addEmojis" style="cursor: pointer;"></i>
                    <i class="fas fa-paperclip me-3" style="cursor: pointer;"></i>
                    <input type="text" v-model="newMessage" class="form-control me-3" aria-label="Escribe tu mensaje"
                        placeholder="Escribe un mensaje..." style="flex-grow: 1;" />
                    <button type="submit" class="btn btn-primary">
                        <i class="fas fa-paper-plane"></i>
                    </button>
                </div>
                <div class="emoji-picker-wrapper" v-if="showEmojiPicker">
                    <Picker :data="emojiIndex" set="twitter" @select="showEmoji" />
                </div>
            </form>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import data from "emoji-mart-vue-fast/data/all.json";
import "emoji-mart-vue-fast/css/emoji-mart.css";
import { Picker, EmojiIndex } from "emoji-mart-vue-fast/src";
let emojiIndex = new EmojiIndex(data);

export default {
    name: "ContentChat",
    components: {
        Picker,
        EmojiIndex
    },
    props: {

    },
    data() {
        return {
            newMessage: "",
            showEmojiPicker: false,
            emojiIndex: emojiIndex,
            selectedChat: {},
            newMessage: "",
        };
    },
    methods: {
        sendMessage() {
            // Lógica para enviar mensaje
            console.log(`Enviando mensaje: ${this.newMessage}`);
            this.newMessage = ""; // Limpiar el campo de mensaje
        },
        addEmojis() {
            this.showEmojiPicker = !this.showEmojiPicker;
        },
        showEmoji(emojis) {
            console.log('show: ', emojis);
        }
    }
}
</script>

<style scoped>
.full-height {
    height: 100%;
    overflow-y: auto;
}

.header {
    display: flex;
    align-items: center;
    background-color: #0aa784;
    color: white;
    padding: 10px;
}

.header img {
    width: 40px;
    height: 40px;
}

.header .contact-info {
    display: flex;
    flex-direction: column;
}

.card-body {
    display: flex;
    flex-direction: column;
    padding: 10px;
}

#messages {
    overflow-y: auto;
    background-color: #fdfbfb;
    color: rgb(12, 12, 12);
}

.message {
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 10px;
    word-wrap: break-word;
    max-width: 70%;
}

.message.client {
    background-color: #dcf8c6;
    text-align: left;
    align-self: flex-start;
}

.message.bot {
    background-color: #f1f1f1;
    text-align: right;
    align-self: flex-end;
}

.message.sent {
    background-color: #ece5dd;
    text-align: right;
    align-self: flex-end;
    margin-left: auto;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.9);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
}

.emoji-mart.emoji-mart-static {
    animation: fadeIn 0.4s ease-out;
    position: absolute;
    margin-top: -500px;
}
</style>
