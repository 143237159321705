<template>

  <div>
<div class="row">


      <div  class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="margin-top:10px;margin-bottom:10px">

        <div class="card2" >

          <div class="card-body">

            <div class="d-flex">

            <h6 class="mb-0" style="font-size: 14px;" :class="this.$store.state.isRTL ? 'ms-2' : ''">
                Modo {{ modo }} activado
              </h6>
              <div class="form-check form-switch ps-0 ms-auto my-auto">
                <input
                  class="form-check-input mt-1 ms-auto"
                  type="checkbox"
                  
                  :checked="this.$store.state.darkMode"
                  @click="setDarkMode"
                />
              </div>
            </div>
            
          </div>

          
       
          
        </div>
 

  </div>





          


      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="    position: absolute; right: 0; bottom: 0; left: 0; padding: 1rem; text-align: center;">
          <button @click="exit()"  class="btn btn-primary btn-sm" type="button" style="width:100%;">Cerrar sesión</button>
      </div> 
  

  </div>
</div>



</template>

<script>
/*eslint-disable */
import myMixin from '../mixin.js'
import { activateDarkMode, deactivateDarkMode } from "@/assets/js/dark-mode";
export default {
  name: "Mas",
  data() {
      const { logout } = myMixin()

      return {
          userId:'', //datos.userId,
          isMobile:this.$isMobile,
          tokenAuth: '', //datos.tokenAuth,
          logout: logout,
          modo:"",
      }
  },
  activated() {
    
    if (this.isMobile){
      this.$eventBus.emit('activeShowMenu')
    }
  
  
},
  methods: {
      setDarkMode() {
      if (this.$store.state.darkMode) {
        localStorage.setItem('skin', "light")
        this.$store.state.darkMode = false;
        this.modo="día";
        deactivateDarkMode();
        return;
      } else {
        localStorage.setItem('skin', "dark");
        this.$store.state.darkMode = true;
        this.modo="noche";
        activateDarkMode();
      }
    },
      exit(){
          this.$eventBus.emit('clearOverlaySidebar', true)
          
          this.logout(true);
      }
  },
  mounted() {
    if(this.$store.state.darkMode){
      this.modo="noche";
    }else{
      this.modo="día";
    }
}
}    
</script>


<style scoped>
.form-check-input:checked {
  background-color: #00b63e !important;
  border-color: #00b63e !important
}

</style>