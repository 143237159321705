<template>
  <div>


      <!-- <PWAPrompt v-if="shown" :install-event="installEvent"/> -->

  <div class="container-fluid">

    <div v-if="isMobile" class="row vh-100" style="    min-height: calc(var(--vh, 1vh)*100);margin: 0px;">
  
      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex d-sm-flex d-md-flex d-md-flex d-lg-flex d-xl-flex justify-content-center text-center align-items-center" >
        <div class="row align-items-center">
          <div class="col-12 col-md-12 col-lg-12" >
            <span class="text-primary">
              <img src="../../src/assets/img/logoWhatSivo.png" style="width: 300px" />
            </span>


            <p class="mb-0 text-center" style="font-size:16px;margin-top:10px">Envía mensajes de forma automática.</p>


            <formulario  :is-login="isLogin" :texto-button="textoButton" />

         
            <hr class="horizontal dark"  style="margin-top:35px"/>

            <a
              target="_blank"
              title="Facebook de EQPay"
              href="https://www.facebook.com/"
            >   <i class="fab fa-facebook-f redes" aria-hidden="true"></i>  </a>
            <a
              target="_blank"
              title="Instagram de EQPay"
              href="https://www.instagram.com/"
            > <i class="fab fa-instagram redes" aria-hidden="true"></i>  </a>
            <a
              target="_blank"
              title="Email de EQPay"
              href="mailto:hola@xtrimproec.com?Subject=Tengo%20una%20pregunta"
            > <i  class="fas fa-envelope redes" aria-hidden="true"></i> </a>
            

            <a
              target="_blank"
              title="Linea de WhatSivo"
              href="https://wa.me/593985067512"
              
            > <i  class="fab fa-whatsapp redes" aria-hidden="true"></i> </a>


            <p style="margin-top: 10px; margin-bottom:0; font-size: 12px;">  <a
              href="#"
              target="_blank"
            >Términos y condiciones</a> -  <a
              href="#"
              target="_blank"
            >Política de privacidad y condiciones de servicios</a> -  <a
              href="#"
              target="_blank"
            >Reclamos</a></p>

            <p style="margin-top: 0px; font-size: 12px;    margin-bottom: 10px;"> Copyright &copy; {{ year }} <a
              href="https://app-whatsivo.com"
              target="_blank"
            >- WhatSivo</a></p>
          

           
           
          </div>
        </div>
      </div>


       



    </div>

    <div v-else class="row vh-100" style="    min-height: calc(var(--vh, 1vh)*100);margin: 0px;">

      <div style="padding:0px;     height: 100vh;" class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 d-none d-sm-none d-md-none d-md-none d-lg-flex d-xl-flex justify-content-center align-items-center"> 
        <v-lazy-image :src="photo" style="object-fit: cover;object-position: top;height: 100%;width: 100%;"  />

      </div>

   

      <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 d-flex d-sm-flex d-md-flex d-md-flex d-lg-flex d-xl-flex justify-content-center text-center align-items-center" >
        <div class="row align-items-center">
          <div class="col-12 col-md-12 col-lg-12" >
            <span class="text-primary">
              <img src="../../src/assets/img/logoWhatSivo.png" style="width: 300px" />
            </span>


            <p class="mb-0 text-center" style="font-size:16px;margin-top:10px">Envía mensajes de forma automática.</p>


            <formulario  :is-login="isLogin" :texto-button="textoButton" />

         
            <hr class="horizontal dark"  style="margin-top:35px"/>

            <a
              target="_blank"
              title="Facebook de WhatSivo"
              href="https://www.facebook.com/whatsivo/"
            >   <i class="fab fa-facebook-f redes" aria-hidden="true"></i>  </a>
            <a
              target="_blank"
              title="Instagram de WhatSivo"
              href="https://instagram.com/whatsivo?igshid=OTJhZDVkZWE="
            > <i class="fab fa-instagram redes" aria-hidden="true"></i>  </a>
            <a
              target="_blank"
              title="Email de WhatSivo"
              href="#"
            > <i  class="fas fa-envelope redes" aria-hidden="true"></i> </a>
            <a
              target="_blank"
              title="Linea de WhatSivo"
              href="https://wa.me/593985067512"
              
            > <i  class="fab fa-whatsapp redes" aria-hidden="true"></i> </a>

            <p style="margin-top: 10px; margin-bottom:0; font-size: 12px;">  <a
              href="#"
              target="_blank"
            >Términos y condiciones</a> -  <a
              href="#"
              target="_blank"
            >Política de privacidad y condiciones de servicios</a> -  <a
              href="#"
              target="_blank"
            >Reclamos</a></p>

            <p style="margin-top: 0px; font-size: 12px;    margin-bottom: 10px;"> Copyright &copy; {{ year }} <a
              href="https://www.whatsivo.com"
              target="_blank"
            >- WhatSivo</a></p>
          

           
           
          </div>
        </div>
      </div>
       



    </div>

  </div>

</div>
</template>


<script>
/*eslint-disable */

import Formulario from "./Formulario.vue";
//import PWAPrompt from './PWAPrompt.vue'

export default {
  name: "signin",
  components: {

    Formulario,
    //PWAPrompt
  },
  data() {
    return {
      isLogin:true,
      textoButton:"Iniciar sesión",
      year: new Date().getFullYear(),
      isMobile:this.$isMobile,
      photo:"/img/landing6.png",
      shown:false,
      installEvent:null,
    }
  },
  beforeMount() {
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault()
      this.installEvent = e
      this.shown = true
    })
  },
  mounted() {

    document.body.classList.remove('dark-version')

    /* this.$eventBus.on('ocultarPWA', () => {
      this.shown= false
    }); */

    document.title = 'WhatSivo - Inicio de sesión';


    },
  methods: {

    
  },

  mounted(){
    let userData = JSON.parse(localStorage.getItem("userData"));
    let revisaSesion = userData ? userData.user : null;

    console.log('revisaSesion: ' + revisaSesion);

    if(revisaSesion != ""){
      this.$router.push({ name: 'Dashboard' })
    }

  },
  created() {

   
  },
  beforeUnmount() {
    
  },
};
</script>

<style scoped>



.container-fluid {
  padding: 0px;
  margin: 0px;
}
</style>
