<template>
    <div>
        <div class="header d-flex align-items-center justify-content-between">
            <div class="contact-info">
                <strong>Chat</strong>
            </div>
            <i class="fas fa-ellipsis-v" style="cursor: pointer; margin-right: 15px;"></i>
        </div>
        <div class="list-group">
            <a v-for="chat in chats" :key="chat.id" @click.prevent="selectChat(chat)"
                class="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
                <div class="d-flex align-items-center">
                    <img :src="chat.img" alt="Profile" class="rounded-circle me-2" width="40">
                    <div>
                        <strong>{{ chat.name }}</strong>
                        <p class="text-muted mb-0">{{ chat.lastMessage }}</p>
                    </div>
                </div>
                <small>{{ chat.date }}</small>
            </a>
        </div>
    </div>
</template>

<script>
export default {
    name: "ListaContactos",
    data() {
        return {
            loading: true,
            isMobile: this.$isMobile,
            chats: [
                {
                    id: 1,
                    name: "Sandra",
                    lastMessage: "Envía la foto...",
                    date: "Ayer",
                    img: "https://app-whatsivo.com:9000/api/medios/getPerfil/perfil_593985067512.jpeg",
                },
                {
                    id: 2,
                    name: "Yesenia",
                    lastMessage: "Esto es una prueba",
                    date: "Sábado",
                    img: "https://app-whatsivo.com:9000/api/medios/getPerfil/perfil_593985067512.jpeg",
                },
                {
                    id: 3,
                    name: "Pedro",
                    lastMessage: "Hi",
                    date: "Sábado",
                    img: "https://app-whatsivo.com:9000/api/medios/getPerfil/perfil_593985067512.jpeg",
                },
                {
                    id: 4,
                    name: "Génesis",
                    lastMessage: "Ven a comer",
                    date: "Sábado",
                    img: "https://app-whatsivo.com:9000/api/medios/getPerfil/perfil_593985067512.jpeg",
                },
                {
                    id: 5,
                    name: "Vidal",
                    lastMessage: "No se que hacer",
                    date: "Sábado",
                    img: "https://app-whatsivo.com:9000/api/medios/getPerfil/perfil_593985067512.jpeg",
                },
            ],
        };
    },
    methods: {

    }
}
</script>

<style scoped>
.full-height {
    height: 90vh;
}

.header {
    display: flex;
    align-items: center;
    background-color: #0aa784;
    color: white;
    padding: 10px;
}

.header img {
    width: 40px;
    height: 40px;
}

.header .contact-info {
    display: flex;
    flex-direction: column;
}
</style>
