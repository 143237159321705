// src/mixins/toastMixin.js
export const toastMixin = {
    methods: {
      showSuccessToast(message) {
        this.$toast.success(message, {
          position: "top-right",
          max: 10
        });
      },
      showErrorToast(message) {
        this.$toast.error(message, {
          position: "top-right",
          max: 10
        });
      }
    }
  };
  