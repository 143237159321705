<template>
    <div>
        <formulario-envios />
    </div>
</template>

<script>
/* eslint-disable */
import FormularioEnvios from "./Envios.vue";

export default {
    name: "sendMessage-default",
    components: {
        FormularioEnvios
    },
    data() {
        return {
        }
    },
    activated() {
    },


    methods: {


    },
    beforeUnmount() {

    },
    mounted() {
        
    }
}
</script>