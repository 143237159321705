<template>


    <div class="card" style="margin-bottom:10px">

      <div class=" card-body">

        <div class="row">

            <div class="col-10 col-sm-10 col-md-11 col-lg-11 col-xl-11"> 

                <p style="margin:10px; font-weight: bold;"><i style="margin-right: 10px;" class="fas fa-list"></i> LISTADO DE MENSAJES NO ENVIADOS</p>

            </div>

          
            <div class="col-2 col-sm-2 col-md-1 col-lg-1 col-xl-1" align="center" style="display:flex"> 
                            <Skeletor v-if="loading" :shimmer="true" height="20" width="100%" style="margin:auto" />
                            <i v-else @click="refresh" style="cursor:pointer; margin:auto" class="fas fa-sync text-sm opacity-10"></i>
                        </div>

        </div>

        <div v-if="loading" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding:0px"> 

            <div class="row">

                <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6"> 

                    <Skeletor :shimmer="true" height="40" width="50%" />

                </div>

                <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6"> 

                    <Skeletor :shimmer="true" height="10" pill width="30%" style="margin-bottom: 10px;" />
                    <Skeletor :shimmer="true" height="40" width="100%" />

                </div>
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"> 
                <table class="table tablesorter" >
                      <thead class="thead-light">
                        <tr>
                          <th> <Skeletor :shimmer="true" height="10" width="100%" /></th>
                          <th><Skeletor :shimmer="true" height="10" width="100%" /></th>
                          <th><Skeletor :shimmer="true" height="10" width="100%" /></th>
                          <th><Skeletor :shimmer="true" height="10" width="100%" /></th>
                          <th><Skeletor :shimmer="true" height="10" width="100%" /></th>
                        </tr>
                      </thead>
                      <tbody>
    
    
                        <tr>
    
                            <td><Skeletor :shimmer="true" height="20" width="100%" /> </td>
                            <td> <Skeletor :shimmer="true" height="20" width="100%" /></td>
                            <td><Skeletor :shimmer="true" height="20" width="100%" /> </td>
                            <td> <Skeletor :shimmer="true" height="20" width="100%" /></td>
                             <td> <Skeletor :shimmer="true" height="20" width="100%" /></td>
                   
                           
    
                  
    
                        </tr>
                        <tr>
                                    
                                    <td><Skeletor :shimmer="true" height="20" width="100%" /> </td>
                                    <td> <Skeletor :shimmer="true" height="20" width="100%" /></td>
                                    <td><Skeletor :shimmer="true" height="20" width="100%" /> </td>
                                    <td> <Skeletor :shimmer="true" height="20" width="100%" /></td>
                                    <td> <Skeletor :shimmer="true" height="20" width="100%" /></td>

                                



                                </tr>
                                <tr>
                                    
                                    <td><Skeletor :shimmer="true" height="20" width="100%" /> </td>
                                    <td> <Skeletor :shimmer="true" height="20" width="100%" /></td>
                                    <td><Skeletor :shimmer="true" height="20" width="100%" /> </td>
                                    <td> <Skeletor :shimmer="true" height="20" width="100%" /></td>
                                    <td> <Skeletor :shimmer="true" height="20" width="100%" /></td>

                                



                                </tr>
                
                      </tbody>
                    </table>

                    </div>

                    <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8"> 
                        <Skeletor :shimmer="true" height="10" pill width="80%" />
                        </div>

                        <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4"> 

                            <div class="row"> 
                                <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4"> 
                                    <Skeletor :shimmer="true" height="10" width="100%" /> 
                                    </div>

                                    <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4" align="center"> 
                                        <Skeletor :shimmer="true" circle size="30" /> 
                                </div>

                                <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4"> 
                                    <Skeletor :shimmer="true" height="10" width="100%" /> 
                                </div>


                            </div>


                        </div>

            </div>

        </div>

        <div v-else-if="notAllow" class="row">
            
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" align="center">

                <img style="width:100px;" src="/img/caution.svg" />

                <p style="margin-top:10px; font-size:14px; ">Tu rol no te permite utilizar la siguiente funcionalidad.</p>


            </div>

        </div>

        <DataTable
        v-else
        :columns="columns"
        :options="options"
        :data="transactions"
        class="table tablesorter"
      width="100%"
        >
        <thead>
            <tr>
            <th >Fecha/Hora</th>
            <th data-priority="1">Nombres y apellidos</th>
            <th>DNI</th>
            <th>Celular</th>
            <th>Email</th>
            <th>Estado</th>
            <th data-priority="2">Acción</th>
            </tr>
        </thead>

        

 

        </DataTable>

      
      </div>

    </div>


  
</template>

<script>

/* eslint-disable */

import DataTable from 'datatables.net-vue3'
import DataTablesCore from 'datatables.net-bs5';
import 'datatables.net-responsive';
import $ from "jquery";


DataTable.use(DataTablesCore);

export default {
components: {

    DataTable


},
props: ['userId', 'tokenAuth', 'idAdmin'],
data() {

return {
    loading: true,
    transactions:[],
    notAllow:false,
    columns : [
        { data: 'dateFormated' },
        { data: 'displayName' },
        { data: 'dni' },
        { data: 'phone' },
        { data: 'email' },
        { data: 'estado' },
        { data: null, render: function(data,type, row, meta){
            return (`<button data-id="${data.idXtrim}"   class="consultar btn btn-primary btn-sm" type="button" style="width:100%;">  Consultar  </button>`)} },
        ], options : {
                            responsive: true,
                            "ordering": false,
                            columnDefs: [
                                                    { responsivePriority: 1, targets: 0 },
                                                    { responsivePriority: 2, targets: -1 }
                                                ],
                            lengthMenu: [
                              [7,15,25, 35, 50, -1],
                              [7,15,25, 35, 50, "All"],
                            ],
                            pageLength: 7, 
                               "language": {
                                                  "search": "Buscar:",
                                                  "sInfo":      "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
                                                    "sInfoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
                                                  "searchPlaceholder": "Ingrese algún dato",
                                                  "zeroRecords": "Lo sentimos, no se encontraron resultados",
                                                  "processing": "Buscando. Espere por favor...",
                                                  "sLengthMenu":     "Mostrar _MENU_ registros",
                                                  "oPaginate": {
                                                          "sFirst":    "Primero",
                                                          "sLast":     "Último",
                                                          "sNext":     "Siguiente",
                                                          "sPrevious": "Anterior"
                                                      },
    
                                                  }
                          },

}
},
computed: {

},
watch: {

},
mounted() {

    $(document).off('click', '.consultar');
   

$(document).on("click", ".consultar", (evt) => {
  
    const data = $(evt.target).data('id');

    console.log(data);
    this.consultarStatus(data); 
});

this.getListado();

},
methods: {

    consultarStatus(id){

        this.$swal({
                
                html:
                '<div class="row"><div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2"></div><div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"><div class="loader"> </div></div><div class="col-7 col-sm-7 col-md-7 col-lg-7 col-xl-7"><p style="margin:0px; font-size:16px; text-align:center; font-weight:bold">Consultando</p></div><div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2"></div></div>',
                showCloseButton: false,
                showCancelButton: false,
                focusConfirm: false,
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                position: 'top',
                });


                this.$https.post('/ventas/consultarVenta/', { tokenAuth: this.tokenAuth, id: id }).then(response => {
                this.$swal.close()

                if (response.data.code == 200) {

                    this.refresh();
                    this.$toast.success(response.data.message, {
                                    position:"bottom-right",
                                    max: 10
                                    })


                } else {
                    this.refresh();
                this.$toast.error(response.data.message, {
                                    position:"bottom-right",
                                    max: 10
                                    })


                                    if(response.data.code === 401){

                                        localStorage.removeItem('userData')
                                            this.$store.state.user="";


                                                            // Redirect to login page
                                                            this.$router.push({ name: 'login' })

                                    }
                    


              
                
                }
                }).catch(error => {

                this.$toast.error("2) Ocurrió un error inesperado: " + error, {
                                    position:"bottom-right",
                                    max: 10
                                    })
                    

                })

    },


refresh() {
    $(document).off('click', '.consultar');
    this.$eventBus.emit("reiniciarListadoVentasAdmin")

      },

      getListado(){


        const data_json = { userId: this.userId, idAdmin: this.idAdmin }
        const data_string = JSON.stringify(data_json)

 

        const dataEncripted = this.$encryptBackoffice.encrypt(data_string);
            this.$https.post('/administracion/getVentasPendientes/', { tokenAuth: this.tokenAuth, data: dataEncripted }).then(response => {

    
            if (response.data.code == 200) {
            this.loading = false;
            this.notAllow=false;



            this.transactions= response.data.transactions;



           
            } else {




            if (response.data.code == 401) {

           
            
                    localStorage.removeItem('userData')
                    this.$store.state.user="";


                 // Redirect to login page
                 this.$router.push({ name: 'login' })
            } else {

                if (response.data.code == 403) {

                this.$router.push({ name: 'Dashboard' })

                }else{

                    if (response.data.code == 404) {

                        this.loading = false;
                        this.notAllow=false;

                    }else{

                        if(response.data.code === 501){
                            this.loading=false;

                            this.notAllow=true;

                            }else{
                                this.getListado();
                            }
                   
                    }
                   
                }
                   
            }
            }
            }).catch(error => {
                      this.getListado();
            })
            },





},
}
</script>

<style lang="scss">
@import "datatables.net-dt";
@import 'datatables.net-responsive-dt';


</style>
  