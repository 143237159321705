<template>

    <div class="row mt-2" style="width: 100%; margin: 0; margin-top: 0px;">
        <div class="col-12 col-sm-12 col-md-8 col-lg-7 col-xl-8">
            <div class="card">
                <div class="card-body">


                    <div class="col-lg-12 col-12 col-md-12 col-xl-12 col-sm-12">
                        <formulario-camapanas />
                    </div>

                    <div class="col-lg-12 col-12 col-md-12 col-xl-12 col-sm-12 mb-2">
                        <div class="d-flex justify-content-between align-items-center">
                            <label class="form-control-label mb-0" style=" font-size: 12px; ">Detalle del
                                mensaje</label>

                        </div>
                    </div>

                    <div class="row mb-2">
                        <div class="col-lg-12 col-12 col-md-12 col-xl-12 col-sm-12 mb-2">
                            <textarea class="form-control" v-model="messageSend" ref="textareaRef"
                                placeholder="Escriba aquí su mensaje..." rows="8"></textarea>
                        </div>

                        <div class="d-flex justify-content-start mb-2">

                            <i class="fas me-3" style="cursor: pointer;" @mouseenter="showMessage('Mayúscula')"
                                @mouseleave="hideMessage" @click="wrapTextWithUpper">M</i>
                            <i class="fas me-3" style="cursor: pointer;" @mouseenter="showMessage('Minúscula')"
                                @mouseleave="hideMessage" @click="wrapTextWithLower">m</i>
                            <i class="fas fa-bold me-3" style="cursor: pointer;" @mouseenter="showMessage('Negrita')"
                                @mouseleave="hideMessage" @click="wrapTextWithAsterisks"></i>
                            <i class="fas fa-italic me-3" style="cursor: pointer;" @mouseenter="showMessage('Cursiva')"
                                @mouseleave="hideMessage" @click="wrapTextWithGuion"></i>

                            <i class="fas fa-smile me-3" style="cursor: pointer; color: #ff9400;"
                                @click="addEmojis"></i>




                            <div v-if="isHovered" class="tooltip-message">{{ tooltipMessage }}</div>

                            <Dropdown :width="200" trigger="hover">
                                <template #trigger>
                                    <i class="fas fa-paperclip me-3" style="cursor: pointer;"></i>
                                </template>
                                <div>
                                    <ul class="list-unstyled mt-2" style="margin-left: 10px;">
                                        <li @click="openFileDialog('Imagenes')">
                                            <i class="fas fa-images"></i> Imagenes
                                        </li>
                                        <li @click="openFileDialog('Documento')">
                                            <i class="fas fa-file-alt"></i> Documentos
                                        </li>
                                    </ul>
                                </div>
                            </Dropdown>


                            <!-- Este div empuja el ícono de descarga al extremo derecho -->
                            <div class="ms-auto d-flex align-items-center" @click="downloaderExcel"
                                style="cursor: pointer;">
                                <i class="fas fa-download" style="margin-right: 8px;"></i>
                                <a style="font-size: 12px;"><b>Formato de Envíos</b></a>
                            </div>


                        </div>



                        <!-- <div class="d-flex justify-content-end">

                            <v-lazy-image :src="imagenUnpload"
                                style="object-fit: cover;object-position: top;height: 100px;width: 100px; display: none;" />
                        </div> -->

                        <input ref="fileInput" type="file" style="display: none;" v-on:change="handleFileUpload">


                        <div class="emoji-picker-wrapper" v-if="showEmojiPicker">
                            <Picker :data="emojiIndex" set="twitter" @select="showEmoji" />
                        </div>

                    </div>



                </div>
            </div>
        </div>


        <div class="col-12 col-sm-12 col-md-4 col-lg-5 col-xl-4 m-top">
            <div class="card">
                <div class="card-body">

                    <div class="col-12" style="margin-bottom:10px">
                        <label class="form-control-label">Vista previa del mensaje</label>
                    </div>

                    <div class="col-lg-12 col-12 col-md-12 col-xl-12 col-sm-12 mb-2 phone-container">
                        <div class="phone">
                            <img :src="phoneImage" alt="Teléfono" class="phone-image" />
                            <div class="message-bubble">
                                <div class="image-container" v-if="imagenUnpload">
                                    <img :src="imagenUnpload" alt="Imagen cargada" class="uploaded-image" />
                                </div>
                                <div v-html="formattedMessage">
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div class="col-lg-12 col-12 col-md-12 col-xl-12 col-sm-12">
            <importart-list />
        </div>

    </div>


</template>

<script>
/* eslint-disable */
import FormularioCamapanas from "./Campanas.vue";
import ImportartList from "./ListaExcel.vue"
import data from "emoji-mart-vue-fast/data/all.json";
import "emoji-mart-vue-fast/css/emoji-mart.css";
import { Picker, EmojiIndex } from "emoji-mart-vue-fast/src";
let emojiIndex = new EmojiIndex(data);
import Dropdown from 'v-dropdown'
import './Envios.scss';

export default {

    name: "formularioEnvios-default",
    components: {
        FormularioCamapanas,
        ImportartList,
        Picker,
        Dropdown

    },

    data() {

        return {
            loading: true,
            isMobile: this.$isMobile,
            emojiIndex: emojiIndex,
            showEmojiPicker: false,
            isHovered: false,
            tooltipMessage: null,
            messageSend: '',
            emojisOutput: "",
            phoneImage: "https://www.whatsivo.com/wp-content/uploads/2024/08/chat.png",
            imagenUnpload: "",
            imagen: '',
            rutaImagen: '',
            extension: ''
        };

    },

    computed: {
        formattedMessage() {

            let formatted = this.messageSend
                .replace(/@@(NOMBRE|VARIABLE1|VARIABLE2|VARIABLE3)@@/g, '<strong>$1</strong>')  // Pone en negrita las palabras específicas entre @@
                .replace(/\*(\S.*?\S)\*/g, '<strong>$1</strong>');  // Pone en negrita el texto entre **

            formatted = formatted.replace(/_(\S.*?\S)_/g, '<em>$1</em>'); //cursiva            
            formatted = formatted.replace(/\*_(\S.*?\S)_\*/g, '<strong><em>$1</em></strong>'); //negrita y cursiva            
            formatted = formatted.replace(/\n/g, '<br>'); //saltos de línea

            return formatted;
        }


    },

    watch: {

    },

    mounted() {

        this.$eventBus.on('getTextoPublicidad', () => {
            this.sendTexto()
        })

        this.$eventBus.on('getImagen', () => {
            this.sendImagen()
        })

    },

    methods: {

        sendTexto() {
            this.$eventBus.emit("getSendText", this.messageSend);
        },

        sendImagen() {
            let datos = {
                extension: this.extension,
                imagen: this.rutaImagen
            }
            this.$eventBus.emit("getSendImagen", datos);
        },

        wrapTextWithUpper() {
            const textarea = this.$refs.textareaRef;
            const start = textarea.selectionStart;
            const end = textarea.selectionEnd;

            if (start !== end) {
                const selectedText = this.messageSend.slice(start, end);
                const beforeText = this.messageSend.slice(0, start);
                const afterText = this.messageSend.slice(end);

                const upperCaseText = selectedText.toUpperCase();
                this.messageSend = `${beforeText}${upperCaseText}${afterText}`;

            }
        },

        wrapTextWithLower() {
            const textarea = this.$refs.textareaRef;
            const start = textarea.selectionStart;
            const end = textarea.selectionEnd;

            if (start !== end) {
                const selectedText = this.messageSend.slice(start, end);
                const beforeText = this.messageSend.slice(0, start);
                const afterText = this.messageSend.slice(end);

                const upperCaseText = selectedText.toLowerCase();
                this.messageSend = `${beforeText}${upperCaseText}${afterText}`;

            }
        },

        wrapTextWithAsterisks() {
            const textarea = this.$refs.textareaRef;
            const start = textarea.selectionStart;
            const end = textarea.selectionEnd;

            if (start !== end) {
                const selectedText = this.messageSend.slice(start, end);
                const beforeText = this.messageSend.slice(0, start);
                const afterText = this.messageSend.slice(end);

                this.messageSend = `${beforeText}*${selectedText}*${afterText}`;

            }
        },

        wrapTextWithGuion() {
            const textarea = this.$refs.textareaRef;
            const start = textarea.selectionStart;
            const end = textarea.selectionEnd;

            if (start !== end) {
                const selectedText = this.messageSend.slice(start, end);
                const beforeText = this.messageSend.slice(0, start);
                const afterText = this.messageSend.slice(end);

                this.messageSend = `${beforeText}_${selectedText}_${afterText}`;

            }
        },

        addEmojis() {
            this.showEmojiPicker = !this.showEmojiPicker;
        },

        showEmoji(emojis) {

            const emoji = emojis.native;
            const textarea = this.$refs.textareaRef;
            const start = textarea.selectionStart + 1;
            const end = textarea.selectionEnd;
            this.messageSend = this.messageSend.slice(0, start) + emoji + this.messageSend.slice(end);
        },

        openFileDialog() {
            this.rutaImagen = '',
                this.imagenUnpload = '',
                this.extension = '',
                this.imagen = ''

            this.$refs.fileInput.click(); // Hace clic en el input de tipo file al hacer clic en el botón
        },

        handleFileUpload(ev) {
            const file = ev.target.files[0];
            this.rutaImagen = file;

            const imageTypes = ['image/jpeg', 'image/png'];
            const documentTypes = ['application/pdf', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
            const maxSize = 2000000; // 2MB

            // Validar que se haya seleccionado un archivo
            if (!file) {
                this.showErrorToast('No se seleccionó ningún archivo');
                return;
            }

            // Verificar tamaño de archivo
            if (file.size > maxSize) {
                this.showErrorToast('El archivo no debe pesar más de 2MB');
                return;
            }


            const isImage = imageTypes.includes(file.type);
            const isDocument = documentTypes.includes(file.type);

            if (!isImage && !isDocument) {
                this.showErrorToast('Tipo de archivo no permitido');
                return;
            }

            const reader = new FileReader();

            reader.onload = (e) => {
                if (isDocument) {
                    this.extension = 'document'
                    this.imagen = 'https://www.whatsivo.com/wp-content/uploads/2022/06/adjunto.jpg';
                } else if (isImage) {
                    this.extension = 'imagen'
                    this.imagen = e.target.result;
                }

                this.imagenUnpload = this.imagen;
            };

            reader.onerror = () => {
                this.showErrorToast('Error al leer el archivo');
            };

            reader.readAsDataURL(file);
        },

        downloaderExcel() {
            this.$swal({
                title: "¿Deseas descargar la plantilla?",
                icon: 'info',
                width: '400px',
                showCancelButton: true,
                confirmButtonText: 'Sí, descargar',
                cancelButtonText: 'Cancelar',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-secondary ml-1',
                    icon: 'custom-icon-color'
                },
                buttonsStyling: false,
                showLoaderOnConfirm: true,
                backdrop: true,
                allowOutsideClick: () => !this.$swal.isLoading(),
            }).then((result) => {
                if (result.isConfirmed) {
                    const url = 'https://www.whatsivo.com/wp-content/uploads/2022/10/publicidad.xlsx';
                    const link = document.createElement('a');
                    link.href = url;
                    link.download = 'Publicidad.xlsx';
                    link.click();

                    this.$swal({
                        title: "¡Descarga completada!",
                        text: "La plantilla se ha descargado exitosamente.",
                        icon: 'success',
                        width: '400px',
                        customClass: {
                            confirmButton: 'btn btn-success',
                        },
                        buttonsStyling: false,
                    });
                }
            });
        },

        showMessage(message) {
            this.isHovered = true;
            this.tooltipMessage = `El texto marcado se convertirá en: ${message}`;
        },

        hideMessage() {
            this.isHovered = false;
        }

    }

}
</script>
<style scoped></style>