<template>
    <div class="card" :style="{ backgroundColor, marginBottom: '10px' }">
        <div class="card-body">
            <div class="row">
                <div class="col-12" style="margin-bottom: 10px; justify-content: space-between; display: flex;">
                    <h5 class="card-title mb-0 text-uppercase font-weight-bold" style="font-size: 12px;">
                        <i :class="icon" style="margin-right: 5px;"></i>{{ title }}
                    </h5>
                    <i v-if="!loading" @click="refresh" style="cursor:pointer;font-size: 12px"
                        class="fas fa-sync text-sm opacity-10"></i>
                </div>
            </div>

            <div class="row" style="text-align: center">
                <div class="col-12" style="margin: 0 auto;">
                    <Skeletor v-if="loading" :shimmer="true" height="38" width="100%" />
                    <p v-else class="font-weight-bolder priceCard" style="text-align:center">
                        <span style="font-size: 23px">{{ cantidad }}</span>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "CardComponent",
    props: {
        title: {
            type: String,
            required: true,
        },
        icon: {
            type: String,
            required: true,
        },
        cantidad: {
            type: Number,
            required: true
        },
        backgroundColor: {
            type: String,
            required: false,
            default: '#FFFFFF', // Color de fondo predeterminado si no se proporciona
        }
    },
    data() {
        return {
            loading: false
        }
    }
}


</script>

<style scoped>
.card {
    overflow: hidden;
}
</style>