<template>
    <div class="card" style="margin-bottom:10px">
        <div class="card-body">
            <div class="row">
                <div class="col-12">
                    <div class="d-flex justify-content-between align-items-center">
                        <div class="d-flex align-items-center w-100">
                            <!-- El texto debe estar alineado a la izquierda -->
                            <h5 class="card-title mb-0 text-uppercase font-weight-bold" style="font-size: 12px;">
                                {{ textButtonQR }}
                            </h5>

                            <!-- Espacio flexible para empujar el ícono a la derecha -->
                            <div class="flex-grow-1"></div>

                            <!-- Botón que siempre está alineado a la derecha -->
                            <i style="cursor:pointer;font-size: 12px" @click="lineasAutorizadas"
                                class="fas fa-check-circle text-sm opacity-10"></i>
                        </div>
                    </div>
                </div>


                <div class="col-xl-12 col-lg-12 col-md-12 col-12 col-sm-12">
                    <div>
                        <!-- Loading spinner -->
                        <div id="app" align="center">
                            <v-lazy-image :src="spiner.img" alt="Loading QR" v-if="loading" />
                        </div>

                        <!-- QR Code -->
                        <div id="app" align="center">
                            <v-lazy-image v-show="qrCode" :src="qrCode" v-if="loading2" style="width: 200px;"
                                alt="QR Code" />
                        </div>
                    </div>

                    <!-- Logs -->
                    <div class="logs" align="center">
                        <ul>
                            <li v-for="log in logs" :key="log.id">{{ log.message }}</li>
                        </ul>
                    </div>
                </div>
            </div>

            <!-- Create Session Button -->
            <div class="row" v-show="ocultarIniciarSession">
                <div class="col-xl-12 col-lg-12 col-md-12 col-12 col-sm-12"
                    style="margin-top:10px; display:flex; justify-content:center;">
                    <button id="conexion" @click="crearSesion" class="btn btn-primary btn-sm" style="width: 300px;">
                        <i>Crear Sesión</i>
                    </button>
                </div>
            </div>


        </div>
    </div>
</template>

<script>
/* eslint-disable */
import { io } from "socket.io-client";


export default {
    name: "qr-default",
    data() {
        return {
            logs: [],
            qrCode: "", // Almacenar el código QR como base64
            loading: false, //Para que se muestre el spinner
            loading2: false,
            ocultarIniciarSession: true,
            textButtonQR: "SCAN QR",
            style: { color: "black", background: "white" },
            spiner: {
                value: document.querySelector('#spinner'),
                img: "https://www.whatsivo.com/wp-content/uploads/2022/06/spinner.gif",
            },
            user: {
                sender: localStorage.getItem("sender"),
                usuario: JSON.parse(localStorage.getItem("userData")).user,
            },
            socket: null,
        };
    },
    computed: {

    },
    mounted() {


        this.compruebaConexion()

        // Conectarse a Socket.io
        //this.socket = io("http://localhost:8000");
        this.socket = io("https://www.backoffice-whatsivo.com:8000");

        // Escuchar el QR Code
        this.socket.on("qr", (data) => {
            if (data.id === this.user.sender) {
                this.logs = []
                this.loading = false //Oculto el spinner
                this.loading2 = true //habilito el qr
                this.qrCode = data.src; // Actualizar la imagen con el QR recibido
                this.logs.push({ id: Date.now(), message: "QR recibido, escanea por favor" });
            }
        });

        // Manejar errores de QR
        this.socket.on("qr-error", (error) => {
            this.logs.push({ id: Date.now(), message: "Error al generar el QR: " + error.error });
        });


        this.socket.on("whatsapp-session-timeout", (sessionData) => {
            this.loading = false
                this.loading2 = false
            this.logs = []
            this.logs.push({ id: sessionData.id, message: sessionData.message });
        });

        // Manejar actualizaciones de sesión
        this.socket.on("whatsapp-session-update", (sessionData) => {
            if (sessionData.id === this.user.sender) {
                this.logs = []
                if (sessionData.ready) {
                    document.getElementById("conexion").disabled = true; // true desactivo el boton
                    this.loading = false //Oculto el spinner
                    this.loading2 = false
                    document.getElementById("conexion").innerHTML = '<i style="margin-right:10px; color: #17c000"></i>Dispositivo Vinculado';
                    this.logs.push({ id: Date.now(), message: "Sesión lista para " + sessionData.id + " - móvil: " + sessionData.movil });
                    this.lineasAutorizadas()
                } else {
                    document.getElementById("conexion").disabled = false; //false activo el boton
                    this.loading = false //Oculto el spinner
                    this.loading2 = false // oculto qr
                    document.getElementById("conexion").innerHTML = '<i style="margin-right:10px; color: #17c000"></i>Crear Sesión';
                    this.logs.push({ id: Date.now(), message: "Sesión desconectada para " + sessionData.id });
                }
            }
        });
    },
    methods: {
        async compruebaConexion() {

            const response = await this.$https.get(`/envios/session-status/${this.user.sender}`)

            if (response.data.ready === true) {
                document.getElementById("conexion").disabled = true; //desactivo el boton
                document.getElementById("conexion").innerHTML = '<i style="margin-right:10px; color: #17c000"></i>Dispositivo Vinculado';
                this.logs.push({ id: Date.now(), message: "Sesión lista para " + response.data.id + " - móvil: " + response.data.movil });
            } else {
                this.loading = false //false desactiva el loading del qr
                document.getElementById("conexion").disabled = false; //habilito el boton
                document.getElementById("conexion").innerHTML = '<i style="margin-right:10px; color: #17c000"></i>Crear Sesión';
                this.logs = []
                this.logs.push({ id: Date.now(), message: "Sesión no está lista o desconectada" });
            }


        },

        crearSesion() {

            this.$swal({
                title: "¿Seguro que deseas conectar tu móvil?",
                icon: 'info',//'question',
                width: '400px',
                showCancelButton: true,
                confirmButtonText: 'Crear sesión',
                cancelButtonText: 'Cancelar',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-secondary ml-1',
                },
                buttonsStyling: false,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    
                },
                allowOutsideClick: () => !this.$swal.isLoading(),
            }).then((result) => {
                if (result.isConfirmed) {

                    this.loading = true; //cargo la imagen del spiner
                    this.logs = []
                    this.logs.push({ id: Date.now(), message: "Creando sesión..." });
                    document.getElementById("conexion").disabled = true; //desactivo el boton
                    document.getElementById("conexion").innerHTML = '<i style="margin-right:10px; color: #17c000" class="fas fa-circle-notch fa-spin"></i>Generando código QR, espere..';

                    // Emitir evento al backend con el sender
                    this.socket.emit("create-session", {
                        id: this.user.sender,
                    });


                }
            });


        },

        async lineasAutorizadas() {
            try {
                const datos = {
                    email: this.user.usuario,
                    sender: this.user.sender
                };

                const response = await this.$https.post(`/system/getAuthorizedLines`, datos);

                if (response.data.code === 200) {

                    this.$swal.fire({
                        icon: "info",
                        title: "<strong>Control de Acceso<strong>",
                        text: "Bienvenido, a nuestra plataforma!",
                        confirmButtonText: "Aceptar",
                        transition: "zoom",
                    });

                } else if (response.data.code === 400 && !response.data.status) {
                    console.error("Error: La sesión no está conectada.");
                } else {
                    console.error("Error desconocido:", response.data);
                }

            } catch (error) {
                console.error("Error en la solicitud:", error);
                const errorMessage = error.response?.data?.message || "Ocurrió un error inesperado";

                this.$swal.fire({
                    icon: "error",
                    title: "<strong>Control de Acceso<strong>",
                    text: errorMessage,
                    confirmButtonText: "Aceptar",
                    customClass: {
                        confirmButton: 'my-confirm-button'
                    },
                    transition: "zoom",
                });

                if(error.response?.data?.code === 600){
                    return
                }
                
                await new Promise(resolve => setTimeout(resolve, 4000));

                localStorage.clear();
                location.reload(true);

                return;
            }

        },

    },
};
</script>

<style scoped>
/* Estilos personalizados */



.swal2-popup {
    display: inline-table !important;
}

.logs ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.logs li {
    margin: 5px 0;
    font-size: 10px;
    padding: 5px;
}
</style>